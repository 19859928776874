<template>
  <div>
    <h1>Login</h1>
    <p class="body-2 mt-3 grey--text">
      Not a member?
      <router-link class="text-decoration-none" to="/register"
        >Register Now</router-link
      >
    </p>

    <v-form v-model="isValid" @submit.prevent="login">
      <InputGroup
        type="email"
        label="Email"
        :rules="[rules.required, rules.validEmail]"
        v-model="form.email"
      />
      <InputGroup
        type="password"
        label="Password"
        :rules="[rules.required, rules.minPasswordLength]"
        v-model="form.password"
      />

      <router-link to="/forgot-password">Forgot Password</router-link>

      <div class="d-flex align-items-center">
        <v-checkbox
          v-model="terms_conditions"
          :rules="[rules.required]"
          color="primary"
          hide-details
          class="mt-0"
        ></v-checkbox>
        <p class="caption mt-3">
          By signing in I have read and agree to the
          <span class="text-decoration-none primary--text">
            Terms and Conditions
          </span>
          and
          <span class="primary--text"> Privacy Policy </span>.
        </p>
      </div>

      <Button
        block
        large
        class="my-10"
        @click="login"
        :loading="loading.user"
        :disabled="!isValid"
        type="submit"
        >Login</Button
      >
    </v-form>
  </div>
</template>

<script>
import { InputGroup, Button } from "@/components";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");
import {
  validEmail,
  required,
  minPasswordLength,
} from "@/helpers/form-validations";

export default {
  name: "login",
  components: { InputGroup, Button },
  data() {
    return {
      isValid: false,
      terms_conditions: false,
      form: {
        email: "",
        password: "",
        role: "seller",
      },
    };
  },
  computed: {
    ...mapState(["loading"]),
    rules() {
      return {
        validEmail,
        required,
        minPasswordLength,
      };
    },
  },
  methods: {
    ...mapActions(["LOGIN"]),
    login() {
      this.LOGIN(this.form);
    },
  },
};
</script>

<style scoped></style>
